import { PUBLIC_APP_NAME } from '../core/constants/env'

const seoConfiguration = {
  500: `Error - ${PUBLIC_APP_NAME}`,
  404: `Not found - ${PUBLIC_APP_NAME}`,
  tenantBlocked: `Blocked - ${PUBLIC_APP_NAME}`,
  accessDenied: `Access Denied - ${PUBLIC_APP_NAME}`,
  authError: `Authentication Error - ${PUBLIC_APP_NAME}`,
  register: `Sign Up - ${PUBLIC_APP_NAME}`,
  login: `Sign In - ${PUBLIC_APP_NAME}`,
  verifyEmail: `Verify Email - ${PUBLIC_APP_NAME}`,
  onboarding: `Onboarding - ${PUBLIC_APP_NAME}`,
  selectCompany: `Select company - ${PUBLIC_APP_NAME}`,
  candidates: `Candidates - ${PUBLIC_APP_NAME}`,
  candidateDetail: (name: string) => `Candidate ${name} - ${PUBLIC_APP_NAME}`,
  jobs: `Jobs - ${PUBLIC_APP_NAME}`,
  jobDetail: (jobDetail: string) => `${jobDetail} - ${PUBLIC_APP_NAME}`,
  jobEdit: (jobEdit: string) => `${jobEdit} - ${PUBLIC_APP_NAME}`,
  jobCreate: `New job - ${PUBLIC_APP_NAME}`,
  jobDuplicate: `New job - ${PUBLIC_APP_NAME}`,
  jobAppliedCareerDetails: `Applied successfully - ${PUBLIC_APP_NAME}`,
  calendar: `Calendar - ${PUBLIC_APP_NAME}`,
  referrals: `Referrals - ${PUBLIC_APP_NAME}`,
  reports: `Reports - ${PUBLIC_APP_NAME}`,
  settingRequisition: `Settings - Requisitions - ${PUBLIC_APP_NAME}`,
  requisition: `Requisitions - ${PUBLIC_APP_NAME}`,
  settingMembers: `Settings - Members - ${PUBLIC_APP_NAME}`,
  settingDepartments: `Settings - Departments - ${PUBLIC_APP_NAME}`,
  settingLocations: `Settings - Locations - ${PUBLIC_APP_NAME}`,
  settingImport: `Settings - Import - ${PUBLIC_APP_NAME}`,
  settingWorkspace: `Settings - Workspace - ${PUBLIC_APP_NAME}`,
  settingAccount: `Settings - Your profile - ${PUBLIC_APP_NAME}`,
  settingPreferences: `Settings - preferences - ${PUBLIC_APP_NAME}`,
  settingCareerPage: `Settings - Career page - ${PUBLIC_APP_NAME}`,
  settingEmailTemplates: `Settings - Email templates - ${PUBLIC_APP_NAME}`,
  settingDisqualifyReasons: `Settings - Disqualify reasons - ${PUBLIC_APP_NAME}`,
  settingCustomFields: `Settings - Custom fields - ${PUBLIC_APP_NAME}`,
  settingHiringPipelines: `Settings - Hiring pipelines - ${PUBLIC_APP_NAME}`,
  settingInterviewKits: `Settings - Interview kits - ${PUBLIC_APP_NAME}`,
  settingIntegrations: `Settings - Integrations - ${PUBLIC_APP_NAME}`,
  settingTags: `Settings - Tags - ${PUBLIC_APP_NAME}`,
  settingReferrals: `Settings - Referrals - ${PUBLIC_APP_NAME}`,
  settingPlans: `Plans - ${PUBLIC_APP_NAME}`,
  settingExpiredPlans: `Plan Expired - ${PUBLIC_APP_NAME}`,
  feedback: `Feedback - ${PUBLIC_APP_NAME}`,
  requisitionsCreate: `New requisition - ${PUBLIC_APP_NAME}`,
  requisitionEdit: (requisitionName: string) =>
    `${requisitionName} - ${PUBLIC_APP_NAME}`,
  expiredTrial: `Plan Expired - ${PUBLIC_APP_NAME}`,
  talentPool: `Talent pool - ${PUBLIC_APP_NAME}`,
  agency: {
    companies: `Companies - ${PUBLIC_APP_NAME}`,
    settings: {
      companyStatus: `Settings - Company status - ${PUBLIC_APP_NAME}`
    }
  },
  placements: `Placements - ${PUBLIC_APP_NAME}`,
  resumes: {
    editor: `Edit resume - ${PUBLIC_APP_NAME}`,
    dashboard: `My Dashboard - ${PUBLIC_APP_NAME}`
  }
}

export default seoConfiguration
